import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { extendTheme, ChakraProvider } from '@chakra-ui/react'

import './css/index.css';
import Navbar from './sections/Navbar';
import App from './App';
import Footer from './sections/Footer';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ChakraProvider>
      <Router>
          <Navbar />
          <App />
          <Footer />
      </Router>
    </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
