import {
    Box,
    chakra,
    Container,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
} from '@chakra-ui/react';
import { FaLinkedin, FaGithub, FaInstagram, FaLastfm, FaGoodreads } from 'react-icons/fa';
import { ReactNode } from 'react';

const SocialButton = ({
                          children,
                          label,
                          href,
                      }: {
    children: ReactNode;
    label: string;
    href: string;
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('white', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.50'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

export default function SmallWithSocial() {
    return (
        <Box
            bg={useColorModeValue('whiteAlpha.50', 'whiteAlpha.900')}
            color={useColorModeValue('blue', 'gray.200')}>
            <Container
                as={Stack}
                maxW={["device-width", "device-width", "70%"]}
                py={8}
                direction={{ base: 'column', md: 'row' }}
                spacing={1}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'center', md: 'center' }}>
                <Text textColor="black">Developed with <a href="https://reactjs.org/">React</a> v18 and <a href="https://chakra-ui.com/">Chakra UI</a>, served with <a href="https://aws.amazon.com/amplify/">AWS Amplify.</a> © {new Date().getFullYear()} Chelsea Kelly-Reif. All rights reserved.</Text>
                <Stack direction={'row'} spacing={6}>
                    <SocialButton label={'LinkedIn'} href={'https://www.linkedin.com/in/chelseakr/'}>
                        <FaLinkedin />
                    </SocialButton>
                    <SocialButton label={'GitHub'} href={'https://github.com/ChelseaKR'}>
                        <FaGithub />
                    </SocialButton>
                    <SocialButton label={'Instagram'} href={'https://instagram.com/chelskr/'}>
                        <FaInstagram />
                    </SocialButton>
                    <SocialButton label={'Last.fm'} href={'https://www.last.fm/user/ChelseaKR'}>
                        <FaLastfm />
                    </SocialButton>
                    <SocialButton label={'Goodreads'} href={'https://www.goodreads.com/user/show/2149441-chelsea'}>
                        <FaGoodreads />
                    </SocialButton>
                </Stack>
            </Container>
        </Box>
    );
}