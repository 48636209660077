import {
    Box,
    Flex,
    Text,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useDisclosure, Heading,
} from '@chakra-ui/react';
import {
    ChevronDownIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';

export default function WithSubnavigation() {

    return (
                <Box>
                    <br/>
                    <Heading as='h1' textAlign="center">
                        <a href="/">Chelsea Kelly-Reif</a>
                    </Heading>
                    <Heading as='h2' size='m' textAlign={['left', 'left', 'left']} textAlign="center">
                        Software Engineering Leader in Civic Tech 🏳️‍⚧️🏳️‍🌈
                    </Heading>
                    <br/>
                    <hr/>
                </Box>
    );
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
        <Link
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'pink.400' }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
}

const NAV_ITEMS: Array<NavItem> = [

]

/*
const NAV_ITEMS: Array<NavItem> = [
    {
        label: 'Experiences',
        children: [
            {
                label: 'Government',
                subLabel: 'My work with government agencies',
                href: '/experiences/government',
            },
            {
                label: 'Private Sector',
                subLabel: 'My work with private sector organizations',
                href: '/experiences/private',
            },
            {
                label: 'Education',
                subLabel: 'My work with educational institutions',
                href: '/experiences/education',
            }
        ],
    },
    {
        label: 'Education',
        children: [
            {
                label: 'Graduate',
                subLabel: 'Graduate-level work in software engineering',
                href: '/education/graduate',
            },
            {
                label: 'Undergraduate',
                subLabel: 'Undergraduate work in computer science',
                href: '/education/undergraduate',
            },
        ],
    },
    {
        label: 'Hobbies',
        children: [
            {
                label: 'Outside',
                subLabel: 'In my free time, I\'ve been known to touch grass',
                href: '/hobbies/outside',
            },
            {
                label: 'Inside',
                subLabel: 'The software engineer\'s natural habitat',
                href: '/hobbies/inside',
            },
        ],
    },
];*/
