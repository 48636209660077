import React, {Component} from "react";
import {Center, Image, Text} from "@chakra-ui/react";
import Featured from "./Featured";
import Headshot from "../src/img/me.JPG"

class Home extends Component {
    render() {
        return (
            <div>
                <Center><Image borderRadius={"full"} maxHeight={["50vw", null, "25vw"]} src={Headshot} alt={"Chelsea Kelly-Reif's headshot."}></Image></Center>
                <Text paddingTop="3vw" textAlign={"left"} fontSize={"lg"}>
                    <p>
                        Chelsea Kelly-Reif, a San Francisco Bay Area based engineering professional, is passionate about crafting equitable technology solutions across various sectors including civic engagement, access to justice & equity, renewable energy, and healthcare. Currently, she serves as a Director of Engineering at <a href="https://coforma.io/" target="_blank">Coforma</a>, where she leads cross-organizational, cross-functional teams to develop innovative software and data solutions that positively impact communities where they're needed the most.
                    </p>
                    <br/>
                    <p>
                        With a strong background in software engineering leadership, Chelsea has held key positions at the California Energy Commission, California Public Utilities Commission, California Department of Social Services, and UC Berkeley, among others. Her technical expertise encompasses an extensive range of technologies, such as React, Node.js, .NET, Python, SQL, Amazon Web Services (AWS), Google Cloud Platform (GCP), and many more.
                    </p>
                    <br/>
                    <p>
                        Chelsea holds Master of Science in Software Engineering from California State University, Fullerton and a Bachelor of Science in Computer Science from the University of Oregon. She lives with her girlfriend Briki, lab mix Olive, and black cat Miko. She can occasionally be found attending concerts, supporting her local queer community, riding her e-bike around the Bay Area, or sleeping.
                    </p>
                </Text>
                <Featured />
            </div>
        )
    }
}

export default Home;