import React, {Component} from "react";
import {Box, Center, Flex, Grid, GridItem, Heading, Image, SimpleGrid, Text} from "@chakra-ui/react";
import CsufLogo from "../src/img/logos/csuf.png"
import CecLogo from "../src/img/logos/cec.jpg"
import CoformaLogo from "../src/img/logos/coforma.png"
import CpucLogo from "../src/img/logos/cpuc.png"
import CdssLogo from "../src/img/logos/cdss.jpg"
import UoLogo from "../src/img/logos/uo.png"

class Featured extends Component {
    render() {
        return (
            <Box pt={"3vw"}>
            <Heading>Featured Organizations</Heading>
                <SimpleGrid columns={[2, 2, 3]} spacing={"40px"}>
                    <Box><Center><Image width="20vh" src={CoformaLogo} alt="Coforma" /></Center></Box>
                    <Box><Center><Image width="20vh" src={CsufLogo} alt="California State University, Fullerton" /></Center></Box>
                    <Box><Center><Image width="20vh" src={CecLogo} alt="California Energy Commission" /></Center></Box>
                    <Box><Center><Image width="20vh" src={CpucLogo} alt="California Public Utilities Commission" /></Center></Box>
                    <Box><Center><Image width="20vh" src={CdssLogo} alt="California Department of Social Services" /></Center></Box>
                    <Box><Center><Image width="20vh" src={UoLogo} alt="University of Oregon" /></Center></Box>
                </SimpleGrid>
            </Box>
        )
    }
}
export default Featured;