import React from "react";
import {BrowserRouter, Link, NavLink, Route, Router, Routes} from "react-router-dom"
import './App.css';
import Home from "./Home";
import { Container } from '@chakra-ui/react'

function App() {
    return (
        <div className="App">
            <Container
                maxW={["device-width", "device-width", "70%"]}
                pt={{base:8}}>
            <div>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </div>
            </Container>
        </div>
  );
}

export default App;